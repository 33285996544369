.h-80 {
  height: 80px;
}
.h-280 {
  height: 280px;
}
.h-370 {
  height: 370px;
}

.h-100-vh {
  height: 100vh;
}

.sidebar-h {
  height: calc(100vh - 70px);
}

/* Percentages */
.mh-p-100 {
  max-height: 100%;
}

@media #{$breakpoint-not-small} {
  .h-370-ns {
    height: 370px;
  }

  .h-100-vh-ns {
    height: 100vh;
  }

  .h-p-100-ns {
    height: 100%;
  }
}
