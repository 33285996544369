*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, body {
  min-height: 100%;
}

button, input, textarea {
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none
}

h1, h2, h3, h4, h5 , h6 {
  font-weight: normal;
}

img {
  max-width: 100%
}