.hv-red {
  &:hover {
    color: $red;
    fill: $red;
  }
}

.hv-grey {
  &:hover {
    color: $grey;
    fill: $grey;
  }
}

.hv-white {
  &:hover {
    color: $white;
    fill: $white;
  }
}

.hv-btn-white {
  &:hover {
    color: $white;
    fill: $white;

    &.bc-blue {
      background-color: $blue;
    }

    &.bc-red {
      background-color: $red;
    }
  }
}

.hv-btn-red {
  &:hover {
    background-color: $white;
    color: $red-dark;
    fill: $red-dark;
  }
}

.hv-btn-blue {
  &:hover {
    background-color: $white;
    color: $blue-dark;
    fill: $blue-dark;
  }
}

.hv-arrow {
  &:hover {
    span {
      transform: translateX(-10px);
    }
  }
}
