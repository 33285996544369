.testimonials-video {
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(239, 241, 245, 0) 0%, rgba(239, 241, 245, 0.3) 40%, rgba(239, 241, 245, 1) 95%);
    content: '';
    z-index: 1;
  }

  @media #{$breakpoint-not-small} {
    &:after {
      display: none;
    }
  }
}
