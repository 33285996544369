// Colors

$red:         #ef4036;
$red-dark:    #b42d25;
$blue:        #312783;
$blue-dark:   #6F63D5;

$grey-dark:   #2f343d;
$grey:        #8a8f97;
$grey-light:  #d9d9d9;
$grey-lighter:#eff1f5;

$white:       #fff;
