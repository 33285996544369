.back-arrow {
  width: 20px;
  height: 20px;
  background-color: $red;
  border-radius: 50%;

  &:after, &:before {
    content: '';
    position: absolute;
    left: 5px;
    display: block;
    width: 8px;
    border: 1px solid #fff;
    border-radius: 2px;
  }

  &:before {
    top: 7px;
    transform: rotate(-45deg);
  }

  &:after {
    top: 11px;
    transform: rotate(45deg);
  }
}