.yt-video {
  top: 50%;
  left: 50%;
  width: 100vh;
  height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);

  // @media #{$breakpoint-large} {
  //   height: 66vw;
  // }
}
