.wrapper {
  margin: 0 auto;
}

.wrapper--text {
  max-width: 420px;
}

.wrapper--big {
  max-width: 332px;

  @media (min-width: 714px) and (max-width: 1046px) {
    max-width: 664px;
  }

  @media (min-width: 1047px) and (max-width: 1378px) {
    max-width: 996px;
  }

  @media (min-width: 1379px) and (max-width: 1710px) {
    max-width: 1328px;
  }

  @media (min-width: 1711px) and (max-width: 2043px) {
    max-width: 1660px;
  }

  @media (min-width: 2044px) {
    max-width: initial;
  }
}