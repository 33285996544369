// Converted Variables
$spacing-none: 0 !default;                            // 0
$spacing-extra-small: .25rem !default;                // 4px
$spacing-small: .5rem !default;                       // 8px
$spacing-medium: 1rem !default;                       // 16px
$spacing-large: 1.5rem !default;                      // 24px
$spacing-extra-large: 2rem !default;                  // 32px
$spacing-extra-extra-large: 4rem !default;            // 64px
$spacing-extra-extra-extra-large: 6rem !default;      // 96px

/* Paddings */
//.pa0 { padding: $spacing-none; }
//.pa-xs { padding: $spacing-extra-small; }
//.pa-s { padding: $spacing-small; }
//.pa-m { padding: $spacing-medium; }
//.pa-l { padding: $spacing-large; }
//.pa-xl { padding: $spacing-extra-large; }
//.pa-xxl { padding: $spacing-extra-extra-large; }
//.pa-xxxl { padding: $spacing-extra-extra-extra-large; }


/* Padding Top */
.pt-s { padding-top: $spacing-small; }
.pt-m { padding-top: $spacing-medium; }
.pt-l { padding-top: $spacing-large; }
.pt-xl { padding-top: $spacing-extra-large; }
.pt-xxl { padding-top: $spacing-extra-extra-large; }
.pt-xxxl { padding-top: $spacing-extra-extra-extra-large; }


/* Padding Right */
.pr-m { padding-right: $spacing-medium; }
.pr-xl { padding-right: $spacing-extra-large; }


/* Padding Bottom */
.pb-l { padding-bottom: $spacing-large; }
.pb-xl { padding-bottom: $spacing-extra-large; }
.pb-xxxl { padding-bottom: $spacing-extra-extra-extra-large; }


/* Padding Left */
.pl-xxl { padding-left: $spacing-extra-extra-large; }


/* Padding Vertical */
.pv-xs {
  padding-top: $spacing-extra-small;
  padding-bottom: $spacing-extra-small;
}

.pv-m {
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
}

.pv-l {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
}


/* Padding Horizontal */
.ph-s {
  padding-right: $spacing-small;
  padding-left: $spacing-small;
}

.ph-m {
  padding-right: $spacing-medium;
  padding-left: $spacing-medium;
}

.ph-l {
  padding-right: $spacing-large;
  padding-left: $spacing-large;
}

.ph-xl {
  padding-right: $spacing-extra-large;
  padding-left: $spacing-extra-large;
}

.ph-xxl {
  padding-right: $spacing-extra-extra-large;
  padding-left: $spacing-extra-extra-large;
}

@media #{$breakpoint-not-small} {
  .pt0-ns { padding-top: 0; }
  .pt-xxxl-ns { padding-top: $spacing-extra-extra-extra-large; }

  .ph-xxl-ns {
    padding-right: $spacing-extra-extra-large;
    padding-left: $spacing-extra-extra-large;
  }
}

@media #{$breakpoint-large} {
  .pt0-l { padding-top: 0; }
  .pt-xxl-l { padding-top: $spacing-extra-extra-large; }
  .pt-xxxl-l { padding-top: $spacing-extra-extra-extra-large; }
}


/* Margins */

/* Margin Top */
.mt-xs { margin-top: $spacing-extra-small; }
.mt-s { margin-top: $spacing-small; }
.mt-m { margin-top: $spacing-medium; }
.mt-l { margin-top: $spacing-large; }
.mt-xl { margin-top: $spacing-extra-large; }

/* Margin Medium */
.mr-xs { margin-right: $spacing-extra-small; }
.mr-s { margin-right: $spacing-small; }
.mr-m { margin-right: $spacing-medium; }

/* Margin Bottom */
.mb-s { margin-bottom: $spacing-small; }
.mb-l { margin-bottom: $spacing-large; }
.mb-xl { margin-bottom: $spacing-extra-large; }
.mb-xxl { margin-bottom: $spacing-extra-extra-large; }

/* Margin Small */
.ml-s { margin-left: $spacing-small; }


@media #{$breakpoint-not-small} {
  .mb-xxl-ns { margin-bottom: $spacing-extra-extra-large; }
}

@media #{$breakpoint-large} {
  .mb-xl-l { margin-bottom: $spacing-extra-large; }
}