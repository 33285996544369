/* Border Radius */
.br-14 { border-radius: 14px; }


/* Border Colors */
.bc-white { border-color: $white; }
.bc-red { border-color: $red; }
.bc-blue { border-color: $blue; }


/* Border Widths */
.bw-2 {
  border-width: 2px;
  border-style: solid;
}



/* Border Right */
.bd-r1 { border-right: 1px solid; }
