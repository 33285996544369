/* Background Colors */
.bg-grey {
  background-color: $grey-lighter;
}

.bg-white {
  background-color: $white;
}

.bg-red {
  background-color: $red;
}

.bg-blue {
  background-color: $blue;
}


/* Text Colors */
.c-grey-dark {
  color: $grey-dark;
}

.c-grey {
  color: $grey;
}

.c-grey-light {
  color: $grey-light;
}

.c-white {
  color: $white;
}

.c-red-dark {
  color: $red-dark;
}

.c-blue-dark {
  color: $blue-dark;
}
