.nav-item {
  p {
    display: none;
  }

  &.selected {
    svg {
      fill: $red;
    }
  }
}

.single-numbers, .numbers-posts {
  .nav-item {
    display: none;
  }

  .nav-item--numbers {
    display: block;

    svg {
      fill: $red;
    }

    p {
      display: block;
    }
  }
}

.single-testimonials, .testimonials-posts {
  .nav-item {
    display: none;
  }

  .nav-item--testimonials {
    display: block;

    svg {
      fill: $red;
    }

    p {
      display: block;
    }
  }
}

.news-posts {
  .nav-item {
    display: none;
  }

  .nav-item--news {
    display: block;

    svg {
      fill: $red;
    }

    p {
      display: block;
    }
  }
}

