.shadow-1 { box-shadow: 0 26px 54px -3px $grey-light; }
.shadow-2 { box-shadow: 0px 26px 54px -4px rgba(239, 64, 54, 0.3); }

.shadow-tile-image {
  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, .4) 95%);
    content: "";
  }
}
