.w-50 {
  width: 50px;
}

.mxw-280 {
  width: 280px;
}

/* Percentages */
.w-p-50 {
  width: 50%;
}

.w-p-100 {
  width: 100%;
}

.mw-p-60 {
  max-width: 60%;
}

@media #{$breakpoint-not-small} {
  .mxw-332-ns {
    width: 332px;
  }

  /* Percentages */
  .w-p-50-ns {
    width: 50%;
  }

  .w-p-100-ns {
    width: 100%;
  }
}

@media #{$breakpoint-large} {
  /* Percentages */
  .w-p-50-l {
    width: 50%;
  }

  .w-p-100-l {
    width: 100%;
  }
}
