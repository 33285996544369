.center-v {
  top: 50%;
  transform: translateY(-50%);
}

@media #{$breakpoint-not-small} {
  .center-v-ns {
    top: 50%;
    transform: translateY(-50%);
  }
}

.center-h {
  left: 50%;
  transform: translateX(-50%);
}
