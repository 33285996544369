.underline {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    width: 56px;
    margin: auto;
    border-bottom-style: solid;
    border-bottom-width: 8px;
  }

  &--red::after {
    border-bottom-color: $red;
  }

  &--blue::after {
    border-bottom-color: $blue;
  }

  &--big {
    &:after {
      width: 90px;
      border-bottom-width: 15px;
    }
  }
}

.vertical-line {
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    height: 46px;
    border-right-width: 2px;
    border-right-style: solid;
  }

  &--top {
    &:after {
      top: 273px;

      @media #{$breakpoint-not-small} {
        top: 363px;
      }
    }
  }

  &--bottom {
    &:after {
      bottom: 273px;
    }
  }

  &--blue {
    &:after {
      border-right-color: blue;
    }
  }

  &--red {
    &:after {
      border-right-color: $red;
    }
  }
}

.hide-line {
  &.vertical-line:after {
    display: none;
  }
}
