.top-0 {
  top: 0;
}
.top-70 {
  top: 70px;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}
.bottom-20 {
  bottom: 20px;
}
.bottom-110 {
  bottom: 110px;
}

.left-0 {
  left: 0;
}
.left-24 {
  left: 1.5rem;
}

/* Percentages */
.left-p-50 {
  left: 50%;
}
