.bg-fade {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(239, 241, 245, 0) 0%, rgba(239, 241, 245, 0) 19%, rgba(239, 241, 245, 1) 95%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(239, 241, 245, 0) 0%,rgba(239, 241, 245, 0) 19%,rgba(239, 241, 245, 1) 95%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(239, 241, 245, 0) 0%,rgba(239, 241, 245, 0) 19%,rgba(239, 241, 245, 1) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
}
