.c-white {
  fill: $white;
}

@media #{$breakpoint-not-small} {
  .c-white-ns {
    fill: $white;
  }
}

.c-grey-light {
  fill: $grey-light;
}

.c-red-dark {
  fill: $red-dark;
}

.c-red {
  fill: $red;
}

.c-blue-dark {
  fill: $blue-dark;
}

.c-blue {
  fill: $blue;
}
