.shadow-image {
  bottom: 26px;
  width: 230px;
  margin: auto;
  opacity: .75;
  filter: blur(54px);
  background-position: bottom;

  @media #{$breakpoint-not-small} {
    width: 280px;
  }
}